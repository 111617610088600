import http from '../clients/Axios'

export default class ObatService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/obat/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get(param) {
    param = param ? param : ''
    return await http.get(`/obat${param}`)
  }

  async getById(id) {
    return await http.get(`/obat/${id}`)
  }

  async getMarginHistory(id) {
    return await http.get(`/obat/${id}/margin_history`)
  }

  async getBatch(id) {
    return await http.get(`/obat/${id}/batch`)
  }

  async updateBatch(id, form) {
    return await http.post(`/obat/${id}/batch`, form)
  }

  async add(form) {
    return await http.post('/obat', form)
  }

  async update(form) {
    return await http.put(`/obat/${form.id}`, form)
  }

  async delete(id) {
    return await http.delete(`/obat/${id}`)
  }

  async uploadImage(id, images) {
    let formData = new FormData()

    if (images) {
      images.forEach((image) => {
        formData.append('images[]', image)
      })
    }

    return await http.post(`/obat/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  async deleteImage(id, images) {
    return await http.delete(`/obat/${id}/images`, {
      data: { images: images },
    })
  }

  async export(sortField, sortOrder, filters) {
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    return http({
      url: `/obat/export?sort=${sort}${filters_param}`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
